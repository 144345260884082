import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL, HTTP_STATUS } from '../../utils/constant'
import { newAxiosInstance } from '../../../axios'
import { TR_TYPE_OPPOSIT } from 'app/utils/constant'
import UrlBuilder from 'app/utils/UrlBuilder'
import { generateDownloadFileName, downloadFile } from 'app/utils/utils';

const initialState = {    
    cheques : [],
    return_cheque_collected: [],
    pending_cheques: [],
    pendingReport: null,
    status: HTTP_STATUS.IDLE,
    deleteStatus: HTTP_STATUS.IDLE
}

export const fetchChequeBySales = createAsyncThunk('cheque/get-cheque-by-sales', async ({salesId, data}, { rejectWithValue }) => {
    try {
        let url = new UrlBuilder().filter(data, 'trType').getUrl();        
        const response = await newAxiosInstance.get(BASE_URL + `/api/Cheque/get-by-sales/${salesId}${url}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchpendingCheques = createAsyncThunk('cheque/get-pending-cheques', async (data = null, { rejectWithValue }) => {
    let url = new UrlBuilder().searchQuery(data).filter(data, 'fromDate').filter(data, 'toDate').filter(data, 'repId').filter(data, 'cheque_Status').getUrl();
    try {
        const response = await newAxiosInstance.get(BASE_URL + `/api/Cheque/get-pending-cheques${url}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchPendingChequeReport = createAsyncThunk('credit/get-pending-cheque-report', async (data = null, { rejectWithValue }) => {
    try {
        let url = new UrlBuilder().filter(data, 'fileType').filter(data, 'stDate').filter(data, 'enDate').filter(data, 'repId').getUrl();
        let responseType = data?.fileType && parseInt(data.fileType) > 0 ? 'blob' : 'text';
        const response = await newAxiosInstance.get(BASE_URL + `/api/Cheque/pending-cheque-report${url}`,{ responseType })
        if(responseType === 'text') return response.data
        let filename = generateDownloadFileName(response.headers['content-disposition']);
        downloadFile(response.data, filename);       
        return null
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchChequeReport = createAsyncThunk('credit/get-cheque-report', async (data = null, { rejectWithValue }) => {
    try {
        let url = new UrlBuilder().filter(data, 'fileType').filter(data, 'stDate').filter(data, 'enDate').filter(data, 'repId')
                        .filter(data, 'Cheque_no').getUrl();
        let responseType = data?.fileType && parseInt(data.fileType) > 0 ? 'blob' : 'text';
        const response = await newAxiosInstance.get(BASE_URL + `/api/Cheque/cheque-report${url}`,{ responseType })
        if(responseType === 'text') return response.data
        let filename = generateDownloadFileName(response.headers['content-disposition']);
        downloadFile(response.data, filename);       
        return null
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const createCheque = createAsyncThunk('cheque/create', async (postdata = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.post(BASE_URL + '/api/Cheque', postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const createBackdateCheque = createAsyncThunk('cheque/backdate/create', async (postdata = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.post(BASE_URL + '/api/Cheque/open-cheques', postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const updateChequeStatus = createAsyncThunk('cheque/update', async (postdata = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.put(BASE_URL + `/api/Cheque/${postdata.status}`, { cheques: postdata.cheques })
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})

export const removeCheque = createAsyncThunk('cheque/remove', async (id = null, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.delete(BASE_URL + `/api/Cheque/${id}`)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response)
    }
})



export const Cehque = createSlice({
    name: 'cheque',
    initialState,
    reducers: {
        resetState: (state, action) => {
            return initialState;
        },
        resetCheque: (state, action) => {
            state.cheques = [];
            return state
        },
        resetReturnChequeCollected: (state, action) => {
            state.return_cheque_collected = [];
            return state
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchChequeBySales.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchChequeBySales.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.cheques = action.payload?.filter(item => item?.tr_type === TR_TYPE_OPPOSIT.CREDIT)
                state.return_cheque_collected = action.payload?.filter(item => item?.tr_type === TR_TYPE_OPPOSIT.DEBIT)
            })
            .addCase(fetchChequeBySales.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(fetchpendingCheques.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchpendingCheques.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.pending_cheques = action.payload
            })
            .addCase(fetchpendingCheques.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(updateChequeStatus.pending, (state, action) => {
                state.deleteStatus = HTTP_STATUS.LOADING
            })
            .addCase(updateChequeStatus.fulfilled, (state, action) => {
                state.deleteStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(updateChequeStatus.rejected, (state, action) => {
                state.deleteStatus = HTTP_STATUS.FAILED
            })
            .addCase(removeCheque.pending, (state, action) => {
                state.deleteStatus = HTTP_STATUS.LOADING
            })
            .addCase(removeCheque.fulfilled, (state, action) => {
                state.deleteStatus = HTTP_STATUS.SUCCEEDED
            })
            .addCase(removeCheque.rejected, (state, action) => {
                state.deleteStatus = HTTP_STATUS.FAILED
            })
            .addCase(fetchPendingChequeReport.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchPendingChequeReport.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.pendingReport = action.payload
            })
            .addCase(fetchPendingChequeReport.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(fetchChequeReport.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchChequeReport.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.pendingReport = action.payload
            })
            .addCase(fetchChequeReport.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            
    }
})

export const getCheques = (state) => state.cheque.cheques;
export const getReturnChequesCollected = (state) => state.cheque.return_cheque_collected;
export const getPendingCheques = (state) => state.cheque.pending_cheques;
export const getPendingChequeReport = (state) => state.cheque.pendingReport;
export const getChequesStatus = (state) => state.cheque.status;
export const getDeleteChequetatus = (state) => state.cheque.deleteStatus;
export const chequeActions = Cehque.actions

export default Cehque.reducer