import '../fake-db';
import { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { MatxTheme } from './components';
import { AuthProvider } from './contexts/JWTAuthContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { DialogProvider } from './contexts/DialogContext';
import { useSelector, useDispatch } from "react-redux";
import { fetchRefresh, getAuth, resetAuth } from 'app/redux/reducers/AuthSlice';
import { newAxiosInstance } from '../axios'
import PositionedSnackbar from './components/PositionedSnackbar';
import { getProduct } from 'app/redux/reducers/ProductSlice';

import routes from './routes';
import ConfirmationDialog from 'app/components/ConfirmationDialog';

let isRefreshing = false;
let queue = [];

const App = () => {
  const content = useRoutes(routes);
  const product = useSelector(getProduct);
  const auth = useSelector(getAuth);
  const dispatch = useDispatch();

  let preventBrowserRefresh = (e) => {
    e.preventDefault()
    e.returnValue = ''
  }

  useEffect(() => {
    const requestIntercepter = newAxiosInstance.interceptors.request.use(
      (config) => {
        if (!config?.headers?.common?.Authorization && auth?.token)
          config.headers.common.Authorization = `Bearer ${auth.token}`
          config.headers.common["X-agency"] = product?.id ?? 0;
        return config;
      },
      (error) => Promise.reject(error)
    );
    const responseIntercepter = newAxiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevRequest = error?.config;
        if (error?.response?.status === 401 && !prevRequest?.sent && auth !== null) {
          console.log(isRefreshing);          
          if (!isRefreshing) {
            isRefreshing = true;
            return dispatch(fetchRefresh({ refreshToken: auth.refreshToken }))
              .unwrap()
              .then((resp) => {
                prevRequest.sent = true;
                prevRequest.headers["Authorization"] = `Bearer ${resp.token}`
                prevRequest.headers["X-agency"] = product?.id ?? 0;
                queue.forEach((queueRequests) => {
                  queueRequests.headers["Authorization"] = `Bearer ${resp.token}`
                  queueRequests.headers["X-agency"] = product?.id ?? 0;
                  queueRequests.sent = true;
                  return newAxiosInstance(queueRequests).catch(ex => {
                      console.error(ex);                      
                  });
                });
                queue = [];
                isRefreshing = false;
                return newAxiosInstance(prevRequest).catch(ex => {
                    console.error(ex);                      
                });
              })
              .catch((ex) => {
                dispatch(resetAuth());
                console.error(ex); 
              });
          } else {
            queue.push(prevRequest);
          }
        }
        return Promise.reject(error);
      }
    );
    return () => {
      newAxiosInstance.interceptors.request.eject(requestIntercepter);
      newAxiosInstance.interceptors.response.eject(responseIntercepter);
    };
  }, [auth, product])

  useEffect(() => {
    window.addEventListener('beforeunload', preventBrowserRefresh)
    return () => {
      window.removeEventListener('beforeunload', preventBrowserRefresh)
    }
  }, [])

  return (    
      <SettingsProvider>
          <MatxTheme>
            <DialogProvider>
              <AuthProvider>{content}</AuthProvider>
              <ConfirmationDialog />
            </DialogProvider>
            <PositionedSnackbar />
          </MatxTheme>
      </SettingsProvider>      
  );
};

export default App;
