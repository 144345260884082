import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { BASE_URL, HTTP_STATUS } from '../../utils/constant'
import { newAxiosInstance } from '../../../axios'

const initialState = {    
    auth : null,
    user: null,
    roles: null,
    status: HTTP_STATUS.IDLE
}

export const fetchLogin = createAsyncThunk('auth/login', async (postdata, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.post(BASE_URL + '/api/Auth/Login', postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const fetchRefresh = createAsyncThunk('auth/refresh', async (postdata, { rejectWithValue }) => {
    try {
        const response = await newAxiosInstance.post(BASE_URL + '/api/Auth/Refresh-Token', postdata)
        return response.data
    }catch(err){
        if(!err.response) throw err;
        return rejectWithValue(err.response.data)
    }
})

export const Auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetAuth: () => {
            return initialState;
        },
        resetStatus: (state, action) => {
            state.status = HTTP_STATUS.IDLE
            return state;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchLogin.pending, (state, action) => {
                state.status = HTTP_STATUS.LOADING
            })
            .addCase(fetchLogin.fulfilled, (state, action) => {
                state.status = HTTP_STATUS.SUCCEEDED
                state.auth = {token: action.payload.token, refreshToken: action.payload.refreshToken}
                state.user = action.payload.user
                state.roles = action.payload.roles
            })
            .addCase(fetchLogin.rejected, (state, action) => {
                state.status = HTTP_STATUS.FAILED
            })
            .addCase(fetchRefresh.fulfilled, (state, action) => {
                state.auth = {token: action.payload.token, refreshToken: action.payload.refreshToken}
            })
    }
})

export const getAuth = (state) => state.auth.auth;
export const getUser = (state) => state.auth.user;
export const getRoles = (state) => state.auth.roles;
export const getAuthStatus = (state) => state.auth.status;
export const getAuthError = (state) => state.auth.error;

export const { resetAuth, resetStatus } = Auth.actions

export default Auth.reducer